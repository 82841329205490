// import { createStore } from 'redux'
// import rootReducer from 'src/reducers'

// const store = createStore(rootReducer)
// export default store

import { applyMiddleware, createStore, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from 'src/reducers'

export default function configureStore(preloadedState = {}) {
  const middlewares = [thunkMiddleware] // loggerMiddleware
  const middlewareEnhancer = composeWithDevTools(applyMiddleware(...middlewares))

  const enhancers = [middlewareEnhancer]
  const composedEnhancers = compose(...enhancers)

  const store = createStore(rootReducer, preloadedState, composedEnhancers)

  return store
}

export { default as configureStore } from './store'
