import * as actionTypes from 'src/actions/actionTypes'

const initialState = {
  myself: undefined,
  customers: undefined,
  admins: undefined,
  toasted: 1,
  isToast: undefined,
  toastContent: '',
  sidebarShow: true,
  payment_history: undefined,
}

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SIDEBAR_SHOW: {
      const sidebarShow = action.sidebarShow
      return {
        ...state,
        sidebarShow,
      }
    }
    case actionTypes.LOGIN_SUCCESS: {
      const { is_authenticated, first_name, last_name, email } = action.payload
      return {
        ...state,
        is_authenticated,
        myself: {
          first_name,
          last_name,
          email,
        },
      }
    }
    case actionTypes.LOGIN_FAIL: {
      const { is_authenticated } = action.payload
      return {
        ...state,
        is_authenticated,
      }
    }
    case actionTypes.NEW_TOAST: {
      const { status, content } = action.payload
      return {
        ...state,
        isToast: status,
        toastContent: content,
        toasted: state.toasted ? state.toasted + 1 : 1,
      }
    }
    case actionTypes.SWAL_PROPS: {
      const { swalProps } = action.payload
      return {
        ...state,
        swalProps,
      }
    }
    case actionTypes.CUSTOMERS: {
      const { customers } = action.payload
      customers.sort((a, b) => (a.origin.created_at < b.origin.created_at ? 1 : -1))
      return {
        ...state,
        customers,
      }
    }
    case actionTypes.ADMINS: {
      const { admins } = action.payload
      admins.sort((a, b) => (a.origin.created_at < b.origin.created_at ? 1 : -1))
      return {
        ...state,
        admins,
      }
    }
    case actionTypes.PAYMENT_HISTORY: {
      const { payment_history } = action.payload
      payment_history.sort((a, b) => (a.date < b.date ? 1 : -1))
      return {
        ...state,
        payment_history,
      }
    }
    case actionTypes.IS_LOADING: {
      // if (action.payload) {
      //   window.scrollTo(0, 0)
      // }
      return {
        ...state,
        isLoading: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default rootReducer
